<script>
	import { Link } from "svelte-routing";
	import TituloSeccion from "../components/TituloSeccion.svelte";
	// Generar un array con 9 elementos
	const items = Array.from({ length: 9 });
</script>

<div class="container pt-3 pb-5">
	<div class="row">
		<div class="col pb-2">
			<TituloSeccion
				tituloSeccion="Blog!"
				iconoClase="bi-newspaper"
				enlaceSeccion=""
			/>
		</div>
	</div>
	<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
		{#each items as _, index}
			<div class="col">
				<div class="card shadow-sm">
					<img
						src="https://ferosor.cl/modules/prestablog/views/img/grid-for-1-7/up-img/thumb_79.jpg"
						class="card-img-top"
						alt="La Solución Perfecta para la Nutrición de tu Ganado en Invierno"
					/>
					<div class="card-body">
						<h5 class="card-title fw-bold">
							La Solución Perfecta para la Nutrición de tu Ganado
							en Invierno
						</h5>
						<p class="card-text">
							Con el invierno llega el desafío de mantener a tu
							ganado saludable y bien alimentado. En este
							artículo, exploramos soluciones prácticas y eficaces
							para optimizar la nutrición de tus animales.
						</p>
						<div
							class="d-flex justify-content-between align-items-center"
						>
							<small class="text-body-secondary">
								Publicado el 9 de Agosto 2024
							</small>
							<div class="btn-group">
								<Link
									to="/articulo"
									type="button"
									class="btn btn-sm btn-success"
								>
									Leer más
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		{/each}
	</div>
</div>

<style>
	.btn-success {
		background-color: var(--verde-qp);
	}
</style>
