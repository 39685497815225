<script>
	// Estado para abrir/cerrar el menú
	let isOpen = false;

	// Función para alternar el estado del menú
	function toggleMenu() {
		isOpen = !isOpen;
	}

	// Función para cerrar el menú al hacer clic en el overlay
	function closeMenu() {
		isOpen = false;
	}
</script>

<!-- Contenedor del menú -->
<div class="menu-container p-2 ms-auto me-2 mx-md-4">
	<!-- Botón para abrir/cerrar el menú -->
	<button class="menu-toggle" on:click={toggleMenu}>
		<i class="bi bi-list me-1"></i>
		{isOpen ? "Cerrar" : "Menú"}
	</button>

	<!-- Overlay -->
	<div class="overlay" class:visible={isOpen} on:click={closeMenu}></div>

	<!-- Menú lateral -->
	<nav class="menu" class:open={isOpen}>
		<div
			class="menu-title d-flex align-items-center pt-2 pb-3 border-bottom mb-2"
		>
			<h2 class="flex-grow-1 m-0">¡Hola!</h2>
			<i class="bi bi-x-circle me-3" on:click={closeMenu}></i>
		</div>
		<ul>
			<li>
				<a href="/c/aves" class="d-flex align-items-center">
					<span>
						<i class="icon icon-gallina"></i>
					</span>
					<span>Aves</span>
				</a>
			</li>
			<li>
				<a href="/c/caballos" class="d-flex align-items-center">
					<span>
						<i class="icon icon-caballo"></i>
					</span>
					<span>Caballos</span>
				</a>
			</li>
			<li>
				<a href="/c/perros-y-gatos" class="d-flex align-items-center">
					<span>
						<i class="icon icon-mascotas"></i>
					</span>
					<span>Perros y Gatos</span>
				</a>
			</li>
			<li>
				<a href="/c/cerdos" class="d-flex align-items-center">
					<span>
						<i class="icon icon-cerdo"></i>
					</span>
					<span>Cerdos</span>
				</a>
			</li>
			<li>
				<a href="/c/vacas" class="d-flex align-items-center">
					<span>
						<i class="icon icon-vaca"></i>
					</span>
					<span>Vacas</span>
				</a>
			</li>
			<li>
				<a href="/c/ovejas-y-cabras" class="d-flex align-items-center">
					<span>
						<i class="icon icon-cabras"></i>
					</span>
					<span>Ovejas y Cabras</span>
				</a>
			</li>
			<li>
				<a href="/c/abejas" class="d-flex align-items-center">
					<span>
						<i class="icon icon-abeja"></i>
					</span>
					<span>Abejas</span>
				</a>
			</li>
			<li>
				<a href="/c/peces" class="d-flex align-items-center">
					<span>
						<i class="icon icon-pez"></i>
					</span>
					<span>Peces</span>
				</a>
			</li>
			<li>
				<a href="/c/higiene" class="d-flex align-items-center">
					<span>
						<i class="icon icon-higiene"></i>
					</span>
					<span>Higiene</span>
				</a>
			</li>
		</ul>
	</nav>
</div>

<style>
	/* Contenedor del menú */
	.menu-container {
		order: 0;
		position: relative;
	}

	/* Botón de apertura/cierre */
	.menu-toggle {
		position: relative;
		background: transparent;
		font-variation-settings: "wght" 500;
		font-size: 1.2rem;
		text-decoration: none;
		color: #2b769f;
		border: none;
		border-radius: 4px;
		padding: 10px 15px;
		cursor: pointer;
		/*z-index: 1100; /* Siempre encima del overlay */
	}

	.menu-toggle:hover {
		color: var(--verde-qp);
	}

	/* Overlay */
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: #0000004d;
		transition: opacity 0.3s ease;
		opacity: 0;
		visibility: hidden;
		z-index: 900; /* Debajo del botón, pero encima del contenido */
	}

	.overlay.visible {
		opacity: 1;
		visibility: visible;
	}

	.menu-title h2 {
		color: var(--verde-600);
		font-variation-settings: "wght" 500;
		font-size: 1.5rem;
		letter-spacing: 1px;
	}
	.menu-title .bi-x-circle {
		font-size: 1.5rem;
	}

	/* Menú lateral */
	.menu {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 310px;
		background: white;
		color: #2b769f;
		transform: translateX(-100%);
		transition: transform 0.3s ease;
		z-index: 1000;
		padding: 20px 20px 10px 20px;
	}

	.menu.open {
		transform: translateX(0);
	}

	.menu ul {
		list-style: none;
		overflow-y: auto;
		padding: 0;
		margin: 0;
		height: calc(100% - 83px);
	}

	.menu ul li {
	}

	.menu ul li a {
		font-size: 1.15rem;
		font-variation-settings: "wght" 400;
		text-decoration: none;
		padding: 0.75rem 0.25rem;
		border-radius: 0.75rem;
		color: white;
		transition: all 0.3s;
	}

	.menu ul li a:hover {
		color: #00d4ff;
	}

	.menu ul li a:hover {
		background-color: var(--verde-100);
		color: var(--verde-700) !important;
		padding-left: 1rem;
		border-left: 5px solid var(--verde-600);
		text-shadow: 2px 2px 0 white;
	}
	.menu ul li a span:first-child {
		margin-right: 0.5rem;
		width: 70px;
		text-align: center;
	}
	.menu ul li a span:first-child i {
		text-shadow: none;
	}

	.menu ul li a span .icon-pez {
		font-size: 1.75rem !important;
	}
	.menu ul li a span .icon-abeja,
	.menu ul li a span .icon-cabras {
		font-size: 2.5rem !important;
	}
	.menu ul li a span .icon-vaca,
	.menu ul li a span .icon-cerdo {
		font-size: 2.75rem !important;
	}
	.menu ul li a span .icon-gallina,
	.menu ul li a span .icon-caballo,
	.menu ul li a span .icon-perro {
		font-size: 4rem !important;
	}
</style>
