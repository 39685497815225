<script>
	import { onMount } from "svelte";
	import TituloSeccion from "../components/TituloSeccion.svelte";
	import Modal from "../components/Modal.svelte";

	export let url_producto;
	let producto = {}; // Variable para almacenar los datos del producto
	let producto_id = null;
	let selectedImage = null;
	let imagenes = {
		producto_nombre: "Producto de Ejemplo",
		miniaturas: [],
	};

	onMount(async () => {
		const url = `https://qualitypro.asiquedo.cl/back/obtenerProducto.php?producto_url=${url_producto}`;
		const respuesta = await fetch(url);
		const result = await respuesta.json();
		//console.log(result); // Muestra la respuesta completa de la API para debug
		if (result.status === 1 && result.data) {
			producto = result.data;
			producto_id = producto.producto_id;
			obtenerFotos(producto_id);
		} else {
			console.error(
				"No se encontraron datos para el producto con ID:",
				id
			);
		}
	});

	// Variable reactiva para almacenar el texto del textarea
	let mensaje = "";
	// Función para enviar el mensaje por WhatsApp
	function enviarWhatsApp(mensaje) {
		if (!mensaje.trim()) {
			alert("Por favor, escribe un mensaje antes de enviar.");
			return;
		}
		const numero = "56932586340"; // Reemplaza con el número de WhatsApp
		const url = `https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`;
		window.open(url, "_blank"); // Abre WhatsApp en una nueva pestaña
	}

	// Obtener Fotos
	async function obtenerFotos(producto_id) {
		const url = `https://qualitypro.asiquedo.cl/back/obtenerFotosProductoId.php?producto_id=${producto_id}`;
		const respuesta = await fetch(url);
		const result = await respuesta.json();
		console.log(result); // Mostrar la respuesta de la API en la consola

		if (result.status === 1 && result.data) {
			imagenes.miniaturas = result.data.map(
				(foto) =>
					`https://qualitypro.asiquedo.cl/back/fotos/${foto.url_foto}`
			);
			selectedImage = imagenes.miniaturas[0]; // Imagen seleccionada para mostrar en el modal y como tamaño medio
		} else {
			console.error("Error al obtener las fotos del producto:", result);
		}
	}

	let isModalOpen = false; // Controla la visibilidad del modal

	// Abre el modal con la imagen seleccionada
	function openModal() {
		isModalOpen = true;
	}

	// Cierra el modal
	function closeModal() {
		isModalOpen = false;
	}
</script>

<div class="bg-white mt-3">
	<div class="container px-lg-5 py-2">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb p-0 m-0 text-lowercase">
				<li class="breadcrumb-item small"><a href="/">Home</a></li>
				<li class="breadcrumb-item small active" aria-current="page">
					{producto.producto_nombre}
				</li>
			</ol>
		</nav>
	</div>
</div>

<div class="container px-lg-5">
	<div class="row rows-cols-2 py-3 mb-3" id="ficha-producto">
		<div class="col">
			<div class="d-flex">
				<div class="img-miniaturas thumbnails" style="width: 100px;">
					{#each imagenes.miniaturas as miniatura}
						<img
							src={miniatura}
							alt="Miniatura"
							class="thumbnail"
							on:click={() => (selectedImage = miniatura)}
						/>
					{/each}
				</div>
				<div class="img-grande ms-3 main-image">
					<img
						src={selectedImage}
						alt="Imagen principal"
						class="medium-image"
						on:click={openModal}
					/>
				</div>
			</div>
		</div>
		<div class="ps-5 col">
			{#if producto && producto.producto_nombre}
				<h1 class="text-primary font-italic fw-bold fs-2">
					{producto.producto_nombre}
				</h1>
			{/if}

			{#if producto && producto.presentacion}
				<p class="fw-bold">Presentación: {producto.presentacion}</p>
			{/if}

			<div class="d-flex flex-column justify-content-between">
				<h2 class="text-verde fs-4 fw-bold">Cotizar Online</h2>
				<p>
					Indícanos por favor cuántos kg de este producto necesitas
					cotizar. (venta mínima: 25 kg). También puedes escribirnos
					sobre consultas técnicas o comerciales.
				</p>
			</div>

			<form class="mb-3">
				<textarea
					class="form-control mt-2 mb-3"
					rows="4"
					bind:value={mensaje}
				></textarea>
				<!-- Botón que muestra el mensaje -->
				<button
					class="w-100 btn btn-success my-3 py-2 text-uppercase rounded-pill"
					type="button"
					on:click={() => enviarWhatsApp(mensaje)}
				>
					<i class="bi bi-whatsapp me-2"></i> Cotizar por WhatsApp
				</button>
			</form>
		</div>
	</div>
	<!-- fin row -->

	<TituloSeccion
		tituloSeccion="Información Técnica"
		iconoClase="bi-clipboard-data"
		enlaceSeccion=""
	/>

	<div class="row pb-5 box-ficha">
		<div class="col">
			<!-- -->
			{#if producto && producto.producto_descripcion}
				<h6 class="fw-bold text-verde fs-4">Descripción</h6>
				<p>{producto.producto_descripcion}</p>
			{/if}

			{#if producto && producto.analisis_quimico}
				<h6 class="fw-bold text-verde fs-4">
					<!--Análisis Quimico-->Composición
				</h6>
				<p>{producto.analisis_quimico}</p>
			{/if}

			{#if producto && producto.presentacion}
				<h6 class="fw-bold text-verde fs-4">Presentación</h6>
				<p>{producto.presentacion}</p>
			{/if}

			{#if producto && producto.contenido}
				<h6 class="fw-bold text-verde fs-4">Contenido</h6>
				<p>{producto.contenido}</p>
			{/if}

			{#if producto && producto.dosificacion}
				<h6 class="fw-bold text-verde fs-4">Dosificación</h6>
				<p>{producto.dosificacion}</p>
			{/if}

			{#if producto && producto.origen}
				<h6 class="fw-bold text-verde fs-4">Origen</h6>
				<p>{producto.origen}</p>
			{/if}

			{#if producto && producto.estado_fisico}
				<h6 class="fw-bold text-verde fs-4">Estado Fisico</h6>
				<p>{producto.estado_fisico}</p>
			{/if}

			{#if producto && producto.caracteristicas_microbiologicas}
				<h6 class="fw-bold text-verde fs-4">
					Caracteristicas Microbiologicas
				</h6>
				<p>{producto.caracteristicas_microbiologicas}</p>
			{/if}

			{#if producto && producto.vida_util}
				<h6 class="fw-bold text-verde fs-4">Vida Útil</h6>
				<p>{producto.vida_util}</p>
			{/if}

			{#if producto && producto.precaucion}
				<h6 class="fw-bold text-verde fs-4">Precaución</h6>
				<p>{producto.precaucion}</p>
			{/if}

			{#if producto && producto.almacenamiento}
				<h6 class="fw-bold text-verde fs-4">Almacenamiento</h6>
				<p>{producto.almacenamiento}</p>
			{/if}
		</div>
	</div>
	<!-- Modal -->
	<Modal isOpen={isModalOpen} imageUrl={selectedImage} onClose={closeModal} />
</div>

<style>
	@media (max-width: 768px) {
		#ficha-producto .col {
			display: block;
			flex: auto;
		}
		.img-grande {
			margin-bottom: 3rem;
		}
		.img-miniaturas img,
		.img-grande img {
			height: auto !important;
		}
	}
	.img-grande,
	.img-miniaturas img {
		border: 5px solid #009dd3;
	}
	.img-grande,
	.img-grande img,
	.img-miniaturas,
	.img-miniaturas img,
	textarea {
		border-radius: 25px;
	}
	.text-verde {
		color: var(--verde-qp);
		text-shadow: none;
		font-weight: 600 !important;
		font-variation-settings: "wght" 600;
	}
	.box-ficha .text-verde {
		margin: 1.5rem 0;
	}
	.box-ficha p {
		margin-bottom: 2rem;
	}
	.text-primary {
		color: #29759e !important;
	}
	.form-control {
		background: var(--azul-100) !important;
		border-color: var(--azul-200) !important;
		padding: 0.75rem 1.5rem !important;
		color: var(--azul-950) !important;
		border: solid 3px rgb(0 157 211 / 40%) !important;
	}
	.form-control::placeholder {
		color: var(--azul-500);
		font-variation-settings: "wght" 500;
	}
	.form-control:focus {
		box-shadow: 0 0 0 0.25rem rgb(68 153 193 / 35%) !important;
	}
	.btn-success {
		background-color: var(--verde-qp) !important;
	}
	.gallery {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.main-image {
		margin-bottom: 20px;
	}

	.medium-image {
		width: 400px;
		height: auto;
		border-radius: 8px;
		cursor: pointer;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		transition: transform 0.3s;
	}

	.medium-image:hover {
		transform: scale(1.05);
	}

	.thumbnails {
		display: flex;
		gap: 10px;
		flex-direction: column;
	}

	.thumbnail {
		width: 100px;
		height: 100px;
		object-fit: cover;
		border: 2px solid transparent;
		border-radius: 8px;
		cursor: pointer;
		transition: border-color 0.3s;
	}

	.thumbnail:hover {
		border-color: #009dd3;
	}

	.thumbnail.active {
		border-color: #009dd3;
	}
</style>
