<script>
	import { Link } from "svelte-routing";
</script>

<main class="bg-celeste mb-5">
	<div
		class="bg-distribuidores d-flex justify-content-center align-items-center flex-column"
	>
		<Link
			to="/distribuir"
			class="btn btn-success text-white mb-5 fs-4 fw-semibold text-uppercase px-5 rounded-pill d-flex btn-distribuidor"
		>
			<span class="pe-5 text-distribuidor">Quiero ser distribuidor</span>
			<span class="icono-distribuidor">
				<img
					src="/assets/images/iconos/icono-mano.svg"
					alt=""
					class="img-fluid"
				/>
			</span>
		</Link>
		<p class="m-0 text-white text-center pt-3 fs-4 fw-semibold">
			Si quieres distribuir productos de Quality Pro, <br />pincha el
			bóton de arriba y cuéntanos sobre tu tienda.
		</p>
	</div>
</main>

<style>
	.bg-celeste {
		background-color: var(--bg-celeste);
	}
	.bg-distribuidores {
		background-image: url(/assets/images/banners/bg-iconos.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 350px;
	}
	:global(.btn-distribuidor) {
		background-color: #56b35f !important;
		position: relative;
		border: 4px solid white !important;
		font-size: 1.25rem !important;
	}
	:global(.btn-distribuidor:hover) {
		background-color: #38813e !important;
	}
	:global(.btn-distribuidor .text-distribuidor) {
		margin-bottom: -2px;
		margin-top: 2px;
	}
	.icono-distribuidor {
		position: absolute;
		width: 58px;
		height: auto;
		right: 0;
	}
</style>
