<script>
	import { Link } from "svelte-routing";
	export let producto = {};
</script>

<article
	class="columna border border-primary-subtle border-3 rounded-4 p-2 position-relative"
>
	<div
		class="d-flex justify-content-center align-items-center imagen-producto mb-2"
	>
		<img
			src="https://qualitypro.asiquedo.cl/back/fotos/{producto.url_foto.startsWith(
				'/'
			)
				? producto.url_foto.substring(1)
				: producto.url_foto}"
			alt={producto.producto_nombre}
			class="img-fluid"
		/>
	</div>
	<h2 class="text-uppercase fs-5 fw-bold">
		{producto.producto_nombre}
	</h2>
	<span class="position-absolute bottom-0 start-0 end-0 p-2">
		<Link
			to={`/p/${producto.producto_url}`}
			class="btn btn-primary text-white rounded-pill w-100 d-flex align-items-center justify-content-center"
		>
			<span class="button-content"
				><img
					src="/assets/images/iconos/icono-derecha.svg"
					alt={producto.producto_nombre}
					class="img-fluid"
				/> Ver detalles</span
			></Link
		>
	</span>
</article>

<style>
	.columna .imagen-producto {
		position: relative;
		overflow: hidden;
	}
	.columna .imagen-producto img {
		transform: scale(1);
		transition: all 0.3s ease-in-out;
	}
	.columna:hover .imagen-producto img {
		transform: scale(1.25);
		transition: all 0.3s ease-in-out;
	}
	.columna.border-primary-subtle {
		border-color: #009dd3 !important;
	}
</style>
