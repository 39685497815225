<script>
	import { Link } from "svelte-routing";
	import FormularioBuscador from "./FormularioBuscador.svelte";
	import MenuVertical from "./MenuVertical.svelte";

	// Función para manejar el desplazamiento
	function scrollToSection() {
		// Evita el comportamiento predeterminado del enlace
		event.preventDefault();
		if (window.location.pathname !== "/") {
			// Redirige al inicio si no estás en la raíz
			window.location.href = "/#nuestros-clientes";
		} else {
			// Desplázate a la sección
			const section = document.querySelector("#nuestros-clientes");
			if (section) {
				section.scrollIntoView({ behavior: "smooth" });
			}
		}
	}
</script>

<header class="py-2">
	<div class="container">
		<div class="d-flex align-items-center justify-content-between">
			<div class="p-2 logo">
				<a href="/" title="QualityPro">
					<img
						src="/assets/images/logo-qualitypro.svg"
						alt="QualityPro"
						class="img-fluid"
						style="height: 80px;"
					/>
				</a>
			</div>
			<MenuVertical />
			<div class="p-2 flex-grow-1">
				<FormularioBuscador />
			</div>
			<div class="p-2">
				<div class="d-flex flex-column ms-2 ms-md-5">
					<a
						class="info-telefonos"
						href="tel:0228144705"
						target="_blank"
					>
						<i class="bi bi-telephone-outbound-fill me-md-3"></i>
						<span class="numero-text">2 2814 4705</span>
					</a>
					<a
						class="info-telefonos info-green"
						href="tel:56932586340"
						target="_blank"
					>
						<i class="bi bi-whatsapp me-3"></i>
						<span class="numero-text">+569 3258 6340</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</header>
<div class="bg-verde box-informaciones">
	<div class="container py-1">
		<div class="row align-items-center">
			<div class="col">
				<p class="m-0">
					<img
						src="/assets/images/iconos/icono-despacho.svg"
						alt="icono-despacho"
						class="img-fluid"
						style="height: 20px; margin-top: -2px;"
					/> Despacho a todo Chile.
				</p>
			</div>
			<div class="col-auto text-end">
				<ul class="list-unstyled m-0 menu-informaciones">
					<li>
						<Link to="/">
							<span>Home</span>
							<span>
								<i
									class="bi bi-house-door-fill"
									aria-hidden="true"
								></i>
							</span>
						</Link>
					</li>
					<li>
						<Link to="/quienes-somos">
							<span>Quienes Somos</span>
							<span>
								<i
									class="bi bi-person-vcard-fill"
									aria-hidden="true"
								></i>
							</span>
						</Link>
					</li>
					<li>
						<a
							href="#"
							on:click={scrollToSection}
							style="cursor: pointer;"
						>
							<span>Nuestros Clientes</span>
							<span>
								<i class="bi bi-people-fill" aria-hidden="true"
								></i>
							</span>
						</a>
					</li>
					<li>
						<Link to="/contacto#donde-estamos">
							<span>Donde estamos</span>
							<span>
								<i class="bi bi-geo-alt-fill" aria-hidden="true"
								></i>
							</span>
						</Link>
					</li>
					<li>
						<Link to="/blog">
							<span>Blog</span>
							<span>
								<i class="bi bi-newspaper" aria-hidden="true"
								></i>
							</span>
						</Link>
					</li>
					<li>
						<Link to="/contacto">
							<span>Contacto</span>
							<span>
								<i
									class="bi bi-envelope-at-fill"
									aria-hidden="true"
								></i>
							</span>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<style>
	.box-informaciones {
		border-bottom: 4px solid white;
		color: white !important;
		font-variation-settings: "wght" 300;
	}
	.info-telefonos {
		font-variation-settings: "wght" 700;
		font-size: 1.2rem;
		color: var(--azul-500);
		text-decoration: none !important;
	}
	.info-telefonos.info-green {
		color: var(--verde-500) !important;
	}

	@media (max-width: 768px) {
		header {
			padding-bottom: 0;
		}
		header .container .d-flex .p-2 {
			padding: 0 !important;
		}
		header .container .d-flex .logo a {
			display: block;
		}
		header .container .d-flex .bi-list {
			font-size: 2.5rem;
			line-height: 2.5rem;
		}
		header .container .d-flex .bi-telephone-outbound-fill {
			font-size: 2rem;
			line-height: 2rem;
		}
		header .container .d-flex .bi-telephone-outbound-fill {
			display: flex;
			align-items: center;
		}
		header .container .nav-menu {
			order: 0;
		}
		header .container .flex-grow-1,
		.info-telefonos .bi-whatsapp,
		.info-telefonos .numero-text,
		.menu-informaciones {
			display: none;
		}
	}
</style>
