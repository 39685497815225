<script>
	import Swiper from "swiper";
	import { Navigation, Autoplay, EffectFlip } from "swiper/modules";

	// Importa los estilos necesarios
	import "swiper/css";
	import "swiper/css/navigation";
	import "swiper/css/effect-flip";

	let swiper;

	// Configuración del slider
	let swiperOptions = {
		modules: [Navigation, Autoplay, EffectFlip],
		effect: "flip", // Aplicamos el efecto flip
		loop: true,
		speed: 1000, // Velocidad de la transición (en milisegundos)
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		flipEffect: {
			slideShadows: true, // Muestra sombras en el cambio de slide
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	};

	// Inicializar Swiper
	import { onMount } from "svelte";
	onMount(() => {
		swiper = new Swiper(".swiper", swiperOptions);
	});
</script>

<div class="swiper">
	<div class="swiper-wrapper">
		<!-- Slides -->
		<div class="swiper-slide">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="Slide 1"
			/>
		</div>
		<div class="swiper-slide">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="Slide 2"
			/>
		</div>
		<div class="swiper-slide">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="Slide 3"
			/>
		</div>
	</div>

	<!-- Botones de navegación -->
	<div class="swiper-button-prev"></div>
	<div class="swiper-button-next"></div>
</div>

<style>
	.swiper {
		width: 100%;
		height: 400px;
	}

	.swiper-slide img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}
</style>
