<script>
	import { Router, Route, Link } from "svelte-routing";
	import Header from "./components/Header.svelte";
	import Home from "./view/Home.svelte";
	import QuienesSomos from "./view/QuienesSomos.svelte";
	import Buscador from "./view/Buscador.svelte";
	import Blog from "./view/Blog.svelte";
	import Contacto from "./view/Contacto.svelte";
	import Distribuidores from "./view/Distribuidores.svelte";
	import CatalogoProductos from "./view/CatalogoProductos.svelte";
	import FichaProducto from "./view/FichaProducto.svelte";
	import SliderHome2 from "./components/SliderHome2.svelte";
	import SliderHome3 from "./components/SliderHome3.svelte";
	import Footer from "./components/Footer.svelte";
	import Articulo from "./view/Articulo.svelte";
</script>

<Router>
	<Header />

	<Route path="/" component={Home} />
	<Route path="/quienes-somos" component={QuienesSomos} />
	<Route path="/distribuir" component={Distribuidores} />
	<Route path="/blog" component={Blog} />
	<Route path="/contacto" component={Contacto} />
	<Route path="/p/:url_producto" component={FichaProducto} let:params />
	<Route path="/c/:url_categoria" component={CatalogoProductos} let:params />
	<Route path="/buscador/" component={Buscador} />
	<Route path="/slider-cube" component={SliderHome2} />
	<Route path="/slider-flip" component={SliderHome3} />
	<Route path="/articulo" component={Articulo} />

	<Footer />
</Router>
