<script>
	import Swiper from "swiper";
	import { Navigation, Autoplay, EffectCoverflow } from "swiper/modules";

	// Importa los estilos necesarios
	import "swiper/css";
	import "swiper/css/navigation";
	import "swiper/css/effect-coverflow";

	let swiper; // Declarar instancia de Swiper

	// Configuración del slider
	let swiperOptions = {
		modules: [Navigation, Autoplay, EffectCoverflow],
		effect: "coverflow", // Aplicamos el efecto
		loop: true,
		speed: 1500, // Velocidad de la transición (en milisegundos)
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		coverflowEffect: {
			rotate: 50,
			stretch: 0,
			depth: 500,
			modifier: 3,
			slideShadows: true,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	};

	// Inicializar Swiper
	import { onMount } from "svelte";
	onMount(() => {
		swiper = new Swiper("#slider-coverflow", swiperOptions);
	});
</script>

<div class="swiper" id="slider-coverflow">
	<div class="swiper-wrapper">
		<!-- Slides -->
		<div class="swiper-slide">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="Slide 1"
			/>
		</div>
		<div class="swiper-slide">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="Slide 2"
			/>
		</div>
		<div class="swiper-slide">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="Slide 3"
			/>
		</div>
	</div>

	<!-- Botones de navegación -->
	<div class="swiper-button-prev"></div>
	<div class="swiper-button-next"></div>
</div>

<style>
	.swiper {
		width: 100%;
		height: auto;
	}

	.swiper-slide img {
		width: 100%;
		display: block;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}
</style>
