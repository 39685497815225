<script>
	export let isOpen = false; // Controla la visibilidad del modal
	export let onClose; // Función para cerrar el modal
	export let imageUrl = ""; // URL de la imagen a mostrar
</script>

{#if isOpen}
	<div class="modal-overlay" on:click={onClose}>
		<div class="modal-content" on:click|stopPropagation>
			<img src={imageUrl} alt="Imagen ampliada" class="modal-image" />
			<button class="close-button" on:click={onClose}>×</button>
		</div>
	</div>
{/if}

<style>
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.modal-content {
		position: relative;
		background: white;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		max-width: 90%;
		height: 90%; /* Limita la altura del modal */
		overflow: hidden; /* Evita scroll en el modal */
	}

	.modal-image {
		width: auto;
		max-width: max-content; /* Se ajusta al ancho del modal */
		height: calc(
			90vh - 40px
		); /* Limita la altura a 90% del viewport menos espacio del padding */
		border-radius: 8px;
		margin: 0 auto;
	}

	.close-button {
		position: absolute;
		top: 10px;
		right: 10px;
		background: red;
		color: white;
		border: none;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		cursor: pointer;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
