<script>
	let nombreEmpresa = "";
	let rut = "";
	let giro = "";
	let comuna = "";
	let email = "";
	let nombreContacto = "";
	let telefono = "";
	let sitioWeb = "";
	let productosInteres = "";
	let responseMessage = "";

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData();
		formData.append("nombreEmpresa", nombreEmpresa);
		formData.append("rut", rut);
		formData.append("giro", giro);
		formData.append("comuna", comuna);
		formData.append("email", email);
		formData.append("nombreContacto", nombreContacto);
		formData.append("telefono", telefono);
		formData.append("sitioWeb", sitioWeb);
		formData.append("productosInteres", productosInteres);

		try {
			const response = await fetch("enviar-correo-distribuidor.php", {
				method: "POST",
				body: formData,
			});

			const result = await response.json();
			responseMessage = result.message;

			if (result.status === "success") {
				nombreEmpresa =
					rut =
					giro =
					comuna =
					email =
					nombreContacto =
					telefono =
					sitioWeb =
					productosInteres =
						""; // Limpiar formulario
			}
		} catch (error) {
			responseMessage = "Ocurrió un error al enviar la solicitud.";
		}
	};
</script>

<main class="bg-celeste mb-5">
	<div
		class="bg-distribuidores d-flex justify-content-center align-items-center flex-column"
	>
		<div class="container">
			<h1
				class="m-0 text-white text-center fs-1 fw-bold p-4 pt-3 text-uppercase"
			>
				Quiero ser distribuidor
			</h1>
			<p class="m-0 text-white text-center fs-4 fw-bold mt-3 px-5">
				Si tienes una tienda de productos veterinarios o agrícolas y
				estás interesado en distribuir productos de Quality Pro, por
				favor completa el formulario. Posteriormente, nos pondremos en
				contacto contigo.
			</p>
		</div>
	</div>
</main>

<div class="container pt-3 pb-5">
	<div class="row justify-content-md-center">
		<div class="col-5">
			<form on:submit={handleSubmit} class="mb-3">
				<label for="nombreEmpresa" class="form-label m-0"
					>Nombre Empresa</label
				>
				<input
					bind:value={nombreEmpresa}
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					required
				/>

				<label for="rut" class="form-label m-0">Rut</label>
				<input
					bind:value={rut}
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					required
				/>

				<label for="giro" class="form-label m-0">Giro</label>
				<input
					bind:value={giro}
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
				/>

				<label for="comuna" class="form-label m-0">Comuna</label>
				<input
					bind:value={comuna}
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
				/>

				<label for="email" class="form-label m-0">Email</label>
				<input
					bind:value={email}
					type="email"
					class="form-control mb-5 p-2 rounded-pill"
					required
				/>

				<label for="nombreContacto" class="form-label m-0"
					>Nombre de persona de contacto</label
				>
				<input
					bind:value={nombreContacto}
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					required
				/>

				<label for="telefono" class="form-label m-0">Teléfono</label>
				<input
					bind:value={telefono}
					type="tel"
					class="form-control mb-5 p-2 rounded-pill"
				/>

				<label for="sitioWeb" class="form-label m-0"
					>Sitio Web (opcional)</label
				>
				<input
					bind:value={sitioWeb}
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
				/>

				<label for="productosInteres" class="form-label m-0"
					>Indicar productos de interés</label
				>
				<textarea
					bind:value={productosInteres}
					class="form-control mb-5 p-2"
					rows="5"
				></textarea>

				<div class="text-center">
					<button
						type="submit"
						class="btn btn-success ps-4 pe-5 text-uppercase rounded-pill"
					>
						<i class="bi bi-envelope-check-fill me-3"></i>
						Enviar
					</button>
				</div>
			</form>
			{#if responseMessage}
				<div class="alert alert-warning mt-3" role="alert">
					{responseMessage}
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.bg-celeste {
		background-color: var(--bg-celeste);
	}
	.bg-celeste p {
		font-size: 1.5rem !important;
		font-variation-settings: "wght" 300;
	}
	.bg-distribuidores {
		background-image: url(/assets/images/banners/bg-iconos.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 350px;
	}
	strong {
		font-variation-settings: "wght" 500;
	}
	.text-primary {
		color: var(--azul-500) !important;
	}
	.form-label {
		font-variation-settings: "wght" 600;
		color: var(--azul-500) !important;
		font-size: 1.25rem !important;
		margin-bottom: 0.5rem !important;
	}
	.form-control,
	.form-select {
		background: var(--azul-100) !important;
		border: solid 3px rgb(0 157 211 / 40%) !important;
		padding: 0.75rem 1.5rem !important;
		color: var(--azul-950) !important;
	}
	.form-control::placeholder {
		color: var(--azul-500);
		font-weight: 500;
		font-variation-settings: "wght" 500;
	}
	.form-control:focus,
	.form-select:focus {
		box-shadow: 0 0 0 0.25rem rgb(68 153 193 / 35%) !important;
	}
	textarea.form-control {
		border-radius: 2rem;
	}
</style>
