<script>
	import { writable } from "svelte/store";
	import ProductoCatalogo from "../components/ProductoCatalogo.svelte";

	export let url_categoria;
	let urlCategoria = url_categoria;
	//console.log("Parámetros recibidos en CatalogoProductos:", url_categoria);

	// Si no se recibe `urlCategoria`, detén la ejecución
	if (!urlCategoria) {
		throw new Error("URL de categoría no recibida");
	}

	let categoria = {}; // Datos de la categoría
	let productos = writable([]); // Lista de productos

	// Función para obtener datos de la categoría
	const fetchCategoria = async () => {
		try {
			const url = `https://qualitypro.asiquedo.cl/back/obtenerCategoria.php?url=${urlCategoria}`;
			const respuesta = await fetch(url);

			if (!respuesta.ok) {
				throw new Error(
					`Error ${respuesta.status}: ${respuesta.statusText}`
				);
			}

			const result = await respuesta.json();
			if (result.status === 1 && result.data) {
				categoria = result.data;
				console.log("Categoría obtenida:", categoria);
			} else {
				console.error("No se encontraron datos para la categoría.");
				categoria = null; // Limpia la categoría si no hay datos
			}
		} catch (error) {
			console.error("Error al obtener la categoría:", error.message);
			categoria = null; // Limpia la categoría en caso de error
		}
	};

	// Función para obtener productos de la categoría
	const fetchProductos = async () => {
		try {
			const url = `https://qualitypro.asiquedo.cl/back/obtenerProductos.php?categoria_url=${urlCategoria}`;
			const respuesta = await fetch(url);

			if (!respuesta.ok) {
				throw new Error(
					`Error ${respuesta.status}: ${respuesta.statusText}`
				);
			}

			const result = await respuesta.json();
			productos.set(result.data || []); // Asegúrate de que productos sea un array
		} catch (error) {
			console.error("Error al obtener productos:", error.message);
			productos.set([]); // Limpia los productos en caso de error
		}
	};

	// Ejecutar las funciones cuando cambia `urlCategoria`
	$: if (urlCategoria) {
		fetchCategoria();
		fetchProductos();
	}
</script>

<main
	class="bg-catalogo d-flex justify-content-center align-items-center align-items-stretch mb-5"
	style={categoria?.categoria_bg
		? `background-image: url('/assets/images/banners/${categoria.categoria_bg}')`
		: ""}
>
	<div
		class="bg-fondo-catalogo d-flex align-items-center justify-content-center"
	>
		<h1 class="m-0 text-white text-center fs-1 fw-bold mt-4 p-5">
			{#if categoria}
				Productos para {categoria.categoria_animal}
			{:else}
				Cargando categoría...
			{/if}
		</h1>
	</div>
</main>

<main class="container mt-4 mb-5 px-lg-5">
	<div class="catalogo">
		{#if $productos.length > 0}
			{#each $productos as producto}
				<ProductoCatalogo {producto} />
			{/each}
		{:else if categoria}
			<p class="text-center mt-5">
				No se encontraron productos para esta categoría.
			</p>
		{:else}
			<p class="text-center mt-5">Cargando productos...</p>
		{/if}
	</div>
</main>

<style>
	.bg-fondo-catalogo {
		width: 100%;
		background: rgba(0, 0, 0, 0.35);
	}
	.bg-catalogo {
		background-position: center;
		background-size: cover;
		height: 400px;
		transition: background-image 0.3s ease-in-out;
	}
	.catalogo {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1200px) {
		.catalogo {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 40px;
		}
		.bg-fondo-catalogo .fs-1 {
			font-size: 4.5rem !important;
			font-variation-settings: "wght" 600;
		}
	}
</style>
