<script>
	import { navigate } from "svelte-routing";

	let s = "";

	const procesarFormularioBusqueda = (event) => {
		event.preventDefault(); // Evita el comportamiento por defecto
		if (!s.trim()) {
			console.log("Campos Vacíos");
			return;
		}

		console.log("La búsqueda a crear es " + s);
		navigate(`/buscador/?s=${encodeURIComponent(s)}`, { replace: true });

		// Limpia el campo después de realizar la busqueda
		s = "";
	};
</script>

<div class="search-panels">
	<form class="search-group" on:submit={procesarFormularioBusqueda}>
		<input
			required
			type="text"
			name="s"
			id="search-input"
			autocomplete="off"
			class="input"
			bind:value={s}
		/>
		<label class="enter-label" for="search-input"
			>¿Qué estás buscando?</label
		>
		<div class="btn-box">
			<button class="btn-search" type="submit">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					height="1em"
					viewBox="0 0 512 512"
					><path
						d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
					></path><circle id="svg-circle" cx="208" cy="208" r="144"
					></circle></svg
				>
			</button>
		</div>
		<div class="btn-box-x">
			<button class="btn-cleare" type="button" on:click={() => (s = "")}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					height="1em"
					viewBox="0 0 384 512"
					><path
						d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
						id="cleare-line"
					></path></svg
				>
			</button>
		</div>
	</form>
</div>
