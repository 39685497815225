<script>
	import TituloSeccion from "./TituloSeccion.svelte";
	import { onMount } from "svelte";

	import Swiper from "swiper";
	import { Autoplay } from "swiper/modules";
	import "swiper/css";

	onMount(() => {
		const swiper = new Swiper("#slider-logos-home", {
			modules: [Autoplay],
			slidesPerView: 2,
			spaceBetween: 7,
			centeredSlides: true,
			loop: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			breakpoints: {
				481: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				992: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
				1200: {
					slidesPerView: 5,
					spaceBetween: 30,
				},
			},
		});
	});
</script>

<div class="container mt-5" id="nuestros-clientes">
	<TituloSeccion
		tituloSeccion="Clientes que nos prefieren"
		iconoClase="icon icon-clientes"
		enlaceSeccion=""
	/>
	<div class="swiper slider-logos-home" id="slider-logos-home">
		<div class="swiper-wrapper">
			<!-- Slides -->
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-aliagro.png"
						alt="Logo cliente 1"
					/>
				</div>
			</div>
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-calca.png"
						alt="Logo cliente 2"
					/>
				</div>
			</div>
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-cals.png"
						alt="Logo cliente 2"
					/>
				</div>
			</div>
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-colun.png"
						alt="Logo cliente 2"
					/>
				</div>
			</div>
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-cooprinsem.png"
						alt="Logo cliente 2"
					/>
				</div>
			</div>
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-covepa.png"
						alt="Logo cliente 2"
					/>
				</div>
			</div>
			<div class="swiper-slide">
				<div class="slider-logos-home__img">
					<img
						src="/assets/images/clientes/cliente-ferosor.png"
						alt="Logo cliente 2"
					/>
				</div>
			</div>
			<!-- Añade más slides según sea necesario -->
		</div>
	</div>
</div>

<style>
	/*
	.swiper {
		width: 100%;
		height: auto;
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.slider-logos-home__img img {
		max-width: 100%;
		height: auto;
		object-fit: contain;
	} */
	.slider-logos-home {
		padding: 10px 20px;
	}

	/* Responsivo para pantallas grandes */
	@media (min-width: 1200px) {
		.slider-logos-home {
			padding: 10px 30px;
		}
	}

	.slider-logos-home .swiper-wrapper {
		height: 130px;
	}

	.slider-logos-home .swiper-slide {
		display: flex;
		align-items: center;
		transition: all 0.3s ease;
	}

	.slider-logos-home
		.swiper-slide:not(.swiper-slide-active)
		.slider-logos-home__img {
		height: 90px;
		opacity: 0.3;
	}

	.slider-logos-home
		.swiper-slide:not(.swiper-slide-active)
		.slider-logos-home__img
		img {
		transform: scale(0.7);
	}

	.slider-logos-home .swiper-slide.swiper-slide-prev .slider-logos-home__img,
	.slider-logos-home .swiper-slide.swiper-slide-next .slider-logos-home__img {
		opacity: 0.7;
	}

	.slider-logos-home__img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 130px;
		padding: 20px;
		border-radius: 10px;
		/*
		box-shadow: 0 3px 5px 0 rgba(20, 34, 115, 0.15);
		background: #fff;*/
		transition: all 0.3s ease;
	}

	/* Responsivo para pantallas medianas */
	@media (min-width: 768px) {
		.slider-logos-home__img {
			padding: 15px;
		}
	}

	.slider-logos-home__img img {
		display: block;
		transition: all 0.3s ease;
	}
</style>
