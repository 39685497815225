<script>
	let nombre = "";
	let email = "";
	let telefono = "";
	let mensaje = "";
	let responseMessage = "";

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData();
		formData.append("nombre", nombre);
		formData.append("email", email);
		formData.append("telefono", telefono);
		formData.append("mensaje", mensaje);

		try {
			const response = await fetch("enviar-correo.php", {
				method: "POST",
				body: formData,
			});

			const result = await response.json();
			responseMessage = result.message;

			if (result.status === "success") {
				nombre = email = telefono = mensaje = ""; // Limpiar el formulario
			}
		} catch (error) {
			responseMessage = "Ocurrió un error al enviar el mensaje.";
		}
	};
</script>

<form on:submit|preventDefault={handleSubmit} class="w-50 me-md-3 ps-lg-5">
	<input
		bind:value={nombre}
		type="text"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu nombre"
	/>
	<input
		bind:value={email}
		type="text"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu e-mail"
	/>
	<input
		bind:value={telefono}
		type="text"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu Teléfono"
	/>
	<textarea
		bind:value={mensaje}
		rows="3"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu Mensaje"
	></textarea>
	<div class="text-end">
		<button type="submit" class="btn btn-primary ps-4 pe-5 text-uppercase">
			<i class="bi bi-envelope-check-fill me-3"></i>
			Enviar
		</button>
	</div>
	{#if responseMessage}
		<div class="alert alert-warning mt-3" role="alert">
			{responseMessage}
		</div>
	{/if}
</form>

<style>
	.form-control {
		background: var(--azul-100) !important;
		border: solid 3px rgb(0 157 211 / 40%) !important;
		padding: 0.75rem 1.5rem !important;
		color: var(--azul-950) !important;
		font-size: 0.9rem;
		font-variation-settings: "wght" 500;
		font-weight: 500;
	}
	.form-control::placeholder {
		color: var(--azul-950);
		font-variation-settings: "wght" 400;
	}
	.form-control:focus {
		box-shadow: 0 0 0 0.25rem rgb(68 153 193 / 35%) !important;
	}
	.btn.btn-primary {
		background: var(--verde-qp) !important;
		border: none !important;
	}
</style>
