<script>
	import { Link } from "svelte-routing";
	import TituloSeccion from "../components/TituloSeccion.svelte";
	import BannerDistribuidores from "../components/BannerDistribuidores.svelte";

	// Generar un array con 9 elementos
	const items = Array.from({ length: 3 });
</script>

<div class="container">
	<div class="row py-5">
		<div class="col-9 box-articulo">
			<TituloSeccion
				tituloSeccion="La Solución Perfecta para la Nutrición de tu Ganado en Invierno"
				iconoClase="bi-newspaper"
				enlaceSeccion=""
			/>

			<p class="card-text">
				Con el invierno llega el desafío de mantener a tu ganado
				saludable y bien alimentado. En este artículo, exploramos
				soluciones prácticas y eficaces para optimizar la nutrición de
				tus animales.
			</p>

			<p class="card-text">
				Durante los meses más fríos, el acceso a pastos frescos se
				reduce considerablemente. Por ello, es esencial proporcionar una
				dieta equilibrada que asegure la ingesta adecuada de nutrientes
				esenciales como proteínas, vitaminas y minerales.
			</p>

			<img
				src="https://ferosor.cl/modules/prestablog/views/img/grid-for-1-7/up-img/thumb_79.jpg"
				class="card-img-top mb-3"
				alt="La Solución Perfecta para la Nutrición de tu Ganado en Invierno"
			/>

			<p class="card-text">
				Una excelente alternativa para la alimentación invernal son los
				forrajes conservados, como el heno y el ensilaje. Estos
				alimentos son ricos en energía y resultan fundamentales para
				suplir las necesidades diarias del ganado en esta época del año.
			</p>

			<p class="card-text">
				Además de los forrajes, es importante considerar el uso de
				suplementos nutricionales. Estos productos están diseñados para
				complementar la dieta y ayudar a prevenir deficiencias,
				fortaleciendo la salud y productividad de tus animales.
			</p>

			<p class="card-text">
				Otro aspecto clave es garantizar el acceso a agua limpia y
				fresca. Aunque en invierno el consumo de agua puede ser menor,
				sigue siendo crucial para el buen funcionamiento del metabolismo
				del ganado.
			</p>

			<p class="card-text">
				Recuerda que la planificación es esencial. Realiza un inventario
				de tus recursos alimenticios y asegúrate de que sean suficientes
				para toda la temporada invernal. Consultar a un nutricionista
				animal puede ser una gran ayuda para diseñar una dieta adecuada.
			</p>

			<p class="card-text">
				Con estos consejos, estarás preparado para enfrentar los
				desafíos del invierno y garantizar que tu ganado reciba la mejor
				nutrición posible. Un ganado saludable es sinónimo de una
				producción eficiente y rentable.
			</p>
		</div>
		<div class="col-3">
			<div class="col box-card-articulos">
				{#each items as _, index}
					<div class="card shadow-sm mb-4">
						<img
							src="https://ferosor.cl/modules/prestablog/views/img/grid-for-1-7/up-img/thumb_79.jpg"
							class="card-img-top"
							alt="La Solución Perfecta para la Nutrición de tu Ganado en Invierno"
						/>
						<div class="card-body">
							<h5 class="card-title fw-bold fs-6">
								La Solución Perfecta para la Nutrición de tu
								Ganado en Invierno
							</h5>
							<div
								class="d-flex justify-content-between align-items-center"
							>
								<small class="text-body-secondary">
									9 Ago 2024
								</small>
								<div class="btn-group">
									<Link
										to="/articulo"
										type="button"
										class="btn btn-sm btn-success"
									>
										Leer más
									</Link>
								</div>
							</div>
						</div>
					</div>
				{/each}
			</div>
		</div>
	</div>
</div>

<BannerDistribuidores />

<style>
	:global(.box-articulo .row) {
		margin-bottom: 2rem !important;
	}
	:global(.box-articulo h2) {
		display: flex;
	}
	:global(.box-articulo h2 .bi-newspaper) {
		font-size: 3rem;
	}
	:global(.box-articulo h2 span) {
		font-size: 2rem;
	}

	.box-articulo .card-text {
		margin-bottom: 1rem;
	}

	.box-articulo img {
		border-radius: 10px;
	}
</style>
